// shared firestore config

import { initializeApp } from 'firebase/app';
import { CACHE_SIZE_UNLIMITED, getFirestore, initializeFirestore, persistentLocalCache, persistentMultipleTabManager } from 'firebase/firestore';
import { GoogleAuthProvider, getAuth } from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: 'AIzaSyCUDM9lIl6m7aWvrBsyFjPmPiVOa5KRwZs',
    authDomain: 'inscribe-mgka.firebaseapp.com',
    projectId: 'inscribe-mgka',
    storageBucket: 'inscribe-mgka.appspot.com',
    messagingSenderId: '662724271659',
    appId: '1:662724271659:web:8de8f0746333297c07d46e',
    measurementId: 'G-XKQXFHSKY5'
};

//setLogLevel('debug');


const app = initializeApp(firebaseConfig);


let db;
// Determine the current URL path
const currentPath = window.location.pathname;

// Check if the current path is not the root or manager page
if (currentPath !== '/' && currentPath !== '/manager') {
    // use offline cache for kiosks
    db = initializeFirestore(app, {
        localCache: persistentLocalCache({
            cacheSizeBytes: CACHE_SIZE_UNLIMITED,
            tabManager: persistentMultipleTabManager()
        })
    });
} else {
    // usual
    db = getFirestore(app);
}

const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const functions = getFunctions(app, 'europe-west2');
const storage = getStorage(app);

// if localhost, connect to the emulator
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    //connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { db, auth, provider, functions, storage };