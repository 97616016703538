/// Show an error message
import { openModal, closeModal } from './modal';
import initSentry from './sentry';
import { delay } from './delay';

// initialise sentry
const Sentry = initSentry();

function createErrorDialog() {
    // Create dialog element
    const dialog = document.createElement('dialog');
    dialog.id = 'error-modal';

    // Create and append article to dialog
    const article = document.createElement('article');

    // Create and append h3 to article
    const h3 = document.createElement('h3');
    h3.id = 'error-title';
    article.appendChild(h3);

    // Create and append h5 to article
    const h5 = document.createElement('h5');
    h5.id = 'error-desc';
    article.appendChild(h5);

    // Create and append small to article for error code
    const small = document.createElement('small');
    small.id = 'error-code';
    article.appendChild(small);

    // Create and append footer to article
    const footer = document.createElement('footer');
    const a = document.createElement('a');
    a.id = 'error-modal-button';
    a.href = '#';
    a.setAttribute('role', 'button');
    a.dataset.target = 'error-modal';
    a.textContent = 'Done';
    a.onclick = async function () {
        console.log('closing error modal');
        closeModal(dialog);
        await delay(500); // wait for the modal to close

        // don't remove from DOM - we may need it again later

        // if a kiosk page, set listening for badge
        if (window.location.href.includes('events') || window.location.href.includes('items') || window.location.href.includes('kiosk') || window.location.href.includes('fire')) {
            console.log('closing error on a kiosk page - re-enabling badge listener');
            window.listeningForBadge = true;
        }
    };
    footer.appendChild(a);

    // Append footer to article, then article to dialog
    article.appendChild(footer);
    dialog.appendChild(article);

    // append dialog to body
    document.body.appendChild(dialog);
}

export async function showError(code, title, message, errorMsg, sound = true, sentry = false, reload = false, customReloadPath = null) {
    // Ensure the dialog exists, create if not
    if (!document.getElementById('error-modal')) createErrorDialog();

    console.warn('Showing error:\n code:', code, '\n title:', title, '\n message:', message, '\n errorMsg:', errorMsg, '\n sound:', sound, '\n sentry:', sentry, '\n reload:', reload, '\n');
    document.getElementById('error-title').innerHTML = title;
    document.getElementById('error-desc').innerHTML = message;
    const progressBar = document.getElementById('progressBar');
    if (progressBar) progressBar.style.display = 'none'; // Check if progressBar exists
    document.getElementById('error-modal-button').innerHTML = 'Done';
    const errorCodeEl = document.getElementById('error-code');
    errorCodeEl.style.display = 'block';
    errorCodeEl.innerHTML = code;
    openModal(document.getElementById('error-modal'));

    // if reload, add another event listener to the button
    if (reload) {
        document.getElementById('error-modal-button').innerHTML = 'Reload';
        document.getElementById('error-modal-button').addEventListener('mousedown', async () => {
            console.log('Reloading...');
            if (customReloadPath) {
                window.location.href = customReloadPath;
            } else {
                location.reload(true);
            }
        });
    } else {
        // Remove the button listener
        document.getElementById('error-modal-button').removeEventListener('mousedown');
        document.getElementById('error-modal-button').innerHTML = 'Done';
    }

    // play sound if needed
    if (!sound) return;
    try {
        const audioURL = new URL('../../sound/error.mp3', import.meta.url);
        var audio = new Audio(audioURL);
        audio.volume = 1;
        audio.play().catch((error) => {
            console.error('Error playing error sound:', error);
        });
    } catch (error) {
        console.error('Error setting up audio:', error);
    }


    if (!sentry) return;
    Sentry.captureException(new Error(errorMsg), [code, title, message]);

}
